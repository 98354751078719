<template>
<Prime-Page>
  <ion-content>
    <ion-grid class="margin-bottom-twenty full-width full-height">
      <ion-row class="ion-justify-content-center full-height">
        <ion-col size-sm="12" size-md="10" size-lg="6" class="full-height">
          <h3>Account Preferences</h3>
          <div v-if="errors.length > 0" class="danger-well notifications-well">
            <div v-for="error in errors" :key="error">
              {{ error + " " }}
            </div>
          </div>
          <form @submit.prevent="checkForm" novalidate="true">
            <ion-row class="ion-align-items-center margin-top-twenty">
              <ion-col class="pad-right-twenty">
                <h6 class="margin-death">Phone Number - Text Messages</h6>
                <div class="text-muted text-small">
                  {{ $languagePack.userSettings.accountPreferences.phoneSMS }}
                </div>
              </ion-col>
              <ion-col size="auto">
                <PrimePhoneInput v-model.trim="phoneSMS" type="twilio" />
              </ion-col>
            </ion-row>
            <ion-row class="ion-align-items-center margin-top-ten">
              <ion-col class="pad-right-twenty">
                <h6 class="margin-death">Phone Number - Voice Calls</h6>
                <div class="text-muted text-small">
                  {{ $languagePack.userSettings.accountPreferences.phoneVoice }}
                </div>
              </ion-col>
              <ion-col size="auto">
                <PrimePhoneInput v-model.trim="phoneVoice" type="twilio" />
              </ion-col>
            </ion-row>
            <ion-row class="margin-top-ten">
              <ion-col>
                <h6>Notification Email Address</h6>
                <input v-model.trim="email" class="form-control" type="text" placeholder="email@example.com" />
              </ion-col>
            </ion-row>
            <ion-row class="margin-top-ten">
              <ion-col>
                <h6>User Name</h6>
                <input v-model.trim="username" class="form-control" type="text" placeholder="email@example.com" disabled="true" />
              </ion-col>
            </ion-row>
            <ion-row class="valign margin-top-ten">
              <ion-col>
                <h6>Admin Permissions</h6>
              </ion-col>
              <ion-col size="auto">
                <ion-row class="valign">
                  <ion-col>{{ user.professional.isUserAdmin ? "Yes" : "No" }}</ion-col>
                  <ion-col>
                    <ion-toggle @ionChange="user.professional.isUserAdmin = $event.detail.checked" :checked="user.professional.isUserAdmin"></ion-toggle>
                  </ion-col>
                </ion-row>
              </ion-col>
            </ion-row>
            <ion-row>
              <ion-col class="text-muted text-small">
                {{ $languagePack.userSettings.accountPreferences.adminPermissions }}
              </ion-col>
            </ion-row>
            <ion-row class="valign margin-top-ten">
              <ion-col size="11">
                <h6>Account Activated</h6>
              </ion-col>
              <ion-col>{{ user.account.active ? "Yes" : "No" }}</ion-col>
            </ion-row>
            <ion-row>
              <ion-col class="text-muted text-small">
                {{ $languagePack.userSettings.accountPreferences.accountActivated }}
              </ion-col>
            </ion-row>
            <ion-row class="margin-top-ten">
              <ion-col>
                <button type="button" @click="confirmResetPassword()" class="prime-button button-block button-secondary margin-top-thirty margin-bottom-ten" :class="{ 'button-pending': isPasswordResetting }">
                  <ion-spinner name="dots" duration="1200" color="primary"></ion-spinner>
                  <span>Send Password Reset Email</span>
                </button>
              </ion-col>
              <!-- <div class="prime-form-block-description">
                {{ $languagePack.userSettings.accountPreferences.passwordReset }}
              </div> -->
              <div v-if="user.professional.lastResetPasswordOn" class="margin-top-ten">
                <span class="text-muted">Password was last reset on </span> <span class="text-bold">{{ user.professional.lastResetPasswordOn | moment("MM/DD/YYYY") }}</span>
              </div>
            </ion-row>

            <ion-row class="text-muted text-small">
              <ion-col>
                {{ $languagePack.userSettings.accountPreferences.passwordReset }}
              </ion-col>
            </ion-row>

            <button type="submit" class="prime-button button-block fade-in margin-top-fourty margin-bottom-twenty" :class="{ 'button-pending': loading }">
              <span>Save Updates</span>
              <ion-spinner name="dots" duration="1200" />
            </button>
          </form>
        </ion-col>
      </ion-row>
    </ion-grid>
  </ion-content>
</Prime-Page>
</template>
<script>
import { send as httpSend } from "@/services/Api";
import PrimePhoneInput from "@/components/Global/PrimePhoneInput";
import isValidPhoneNumber from "@/utils/FormHelpers/isValidPhoneNumber";
import PrimePage from "@/components/Global/PrimePage";

export default {
  name: "AccountPreferences",
  components: {
    PrimePhoneInput,
    PrimePage
  },
  props: {
    user: Object
  },
  data() {
    return {
      loading: false,
      isPasswordResetting: false,
      errors: []
    };
  },
  computed: {
    email: {
      get() {
        return this.getCommChannel("email");
      },
      set(val) {
        this.updateCommChannel("email", val);
      }
    },
    username: {
      get() {
        return this.user.account.userName;
      }
    },
    phoneSMS: {
      get() {
        return this.getCommChannel("sms");
      },
      set(val) {
        this.updateCommChannel("sms", val);
      }
    },
    phoneVoice: {
      get() {
        return this.getCommChannel("voice");
      },
      set(val) {
        this.updateCommChannel("voice", val);
      }
    }
  },
  methods: {
    getCommChannel: function(channel) {
      const found = this.user.accountChannelList.find(el => el.channel === channel);
      return found ? found.identifier : null;
    },
    updateCommChannel: function(channel, value) {
      const found = this.user.accountChannelList.find(el => el.channel === channel);
      return found ? (found.identifier = value) : null;
    },
    validateEmail: function() {
      if (this.email != "" && this.email != undefined && /^\w+([.-]?\w+)*@\w+([.-]?\w+)*(\.\w{2,3})+$/.test(this.email)) {
        return 1;
      } else {
        return 0;
      }
    },
    checkForm: function() {
      // reset errors well
      this.errors = [];
      const sms = this.phoneSMS;
      const voice = this.phoneVoice;

      if ((sms && !isValidPhoneNumber(sms, false)) || (voice && !isValidPhoneNumber(voice, false))) {
        this.errors.push("Please enter a valid 10 digit phone number including area code");
      }
      if (!this.validateEmail()) {
        this.errors.push("Please enter a valid email address");
      }
      if (!this.errors.length) {
        this.sendPayload();
      }
    },
    sendPayload: async function() {
      this.loading = true;
      const method = "put";
      const path = document.config.professionalSettings;
      const payload = this.user;

      httpSend({ path, method, authToken: true, payload })
        .then(() => {
          //this.currentPatient = result.data;
          this.$ionic.toastController
            .create({
              header: "Success!",
              message: "User Settings have been updated",
              duration: 1000,
              color: "primary",
              mode: "ios"
            })
            .then(m => m.present());

          this.loading = false;
          this.formIsChanged = false;
        })
        .catch(error => {
          this.$ionic.toastController
            .create({
              header: "Error",
              message: "Could not save User Settings. Please try again later.",
              duration: 5000,
              position: "top"
            })
            .then(m => m.present());

          khanSolo.log(error);
          this.loading = false;
        });
    },
    confirmResetPassword() {
      this.$ionic.alertController
        .create({
          header: "Confirm Password Reset",
          message: "Are you sure you want to invoke password reset email for this user?",
          mode: "ios",
          buttons: [
            {
              text: "No",
              role: "cancel",
              cssClass: "secondary",
              handler: () => {}
            },
            {
              text: "Yes",
              handler: () => {
                this.triggerResetPassword();
              }
            }
          ]
        })
        .then(a => a.present());
    },
    triggerResetPassword() {
      this.isPasswordResetting = true;
      const payload = this.email;
      const method = "post";
      const path = document.config.forgotPassword;
      const headers = {
        "Content-Type": "text/plain"
      };

      httpSend({ path, method, payload, headers })
        .then(() => {
          //this.currentPatient = result.data;
          this.$ionic.toastController
            .create({
              header: "Email Sent!",
              message: "An email will be sent to the account holder with further instructions to reset the password.",
              duration: 10000,
              color: "primary",
              mode: "ios"
            })
            .then(m => m.present());

          this.isPasswordResetting = false;
          this.formIsChanged = false;
        })
        .catch(error => {
          this.$ionic.toastController
            .create({
              header: "Error",
              message: "Could not invoke reset password email. Please try again later.",
              duration: 5000,
              position: "top"
            })
            .then(m => m.present());

          khanSolo.log(error);
          this.isPasswordResetting = false;
        });
    }
  }
};
</script>
